<template>
  <div class="register">
    <PageSubHeader :pageSubHeader="pageSubHeader" />
    <main>
      <section id="pointApply">
        <div class="w1300">

        <div class="top">

          <div class="main_title_box">
            <h3 class="main_title txt-bold">積分申請查詢</h3>
            <div class="img_box">
              <img src="img/index/icon_title2.svg" alt="">
            </div>
          </div>

          <div class="top_description">
            <p class="small txt-red">※請於活動結束後一個月內，至成果報告上傳簽到表格掃描檔及積分表格檔，逾期恕不計分。</p>
            <div class="filter">
              <form action="" method="">
                <div class="time_filter">
                  <!-- 透過關鍵字篩選 -->
                  <div class="input-wrap">
                    <p class="small txt-bold txt-dark_gray">搜尋活動主題</p>
                    <input class="search" type="text" name="key" placeholder="活動主題">
                    <button type="submit">查詢</button>
                  </div>
                </div>
              </form>
            </div>
          </div>

        </div>

        <div class="bottom">

          <!-- table area -->
          <div class="table_box">
            <table class="txt-center">
              <thead>
                <tr>
                  <th class="min180"><p>活動日期</p></th>
                  <th class="td-main txt-left"><p>活動主題</p></th>
                  <th class="td-main txt-left"><p>申請單位</p></th>
                  <th class="min100"><p>進度</p></th>
                  <th class="min240"><p>下載/操作</p></th>
                </tr>
              </thead>
              <tbody id="table_data">
                <!-- <?php for($i=0;$i<2;$i++) { ?> -->
                  <tr>
                    <td data-title="活動日期" class="nowrap"><p>2020/03/01<br> 08:00</p></td>
                    <td data-title="活動主題" class="td-main txt-left"><p>帶狀泡疹疾病負擔與疫苗介紹</p></td>
                    <td data-title="申請單位" class="td-main txt-left"><p>大安婦幼醫院</p></td>
                    <td data-title="進度" class="txt-light_green nowrap"><p>申請</p></td>
                    <td data-title="下載/操作" class="td-download td-download-multiple txt-left">
                      <div class="download_box">
                        <!-- 申請狀態：無任何操作 -->
                        <!-- <a href="download/居家醫療-積分回覆函.docx" class="small" download>
                        積分回覆函</a> -->
                        <!-- <a href="register_points_search_payment.php" class="payment small">
                          前往繳費</a> -->
                        <!-- <a href="download/積分表格檔範本.xls" class="small" download>
                        積分表格檔</a> -->
                        <!-- <a href="#/" class="receipt small" download>下載收據</a> -->
                        <!-- <a href="register_points_search_upload.php" class="upload small">
                          上傳成果報告</a> -->
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td data-title="活動日期" class="nowrap"><p>2020/03/01<br> 08:00</p></td>
                    <td data-title="活動主題" class="td-main txt-left"><p>女性生殖力衰竭_如何保有一線生機</p></td>
                    <td data-title="申請單位" class="td-main txt-left"><p>埔基醫療財團法人埔里基督教醫院</p></td>
                    <td data-title="進度" class="txt-red nowrap"><p>未繳費</p></td>
                    <td data-title="下載/操作" class="td-download td-download-multiple txt-left">
                      <div class="download_box">
                        <!-- 未繳費狀態：顯示 積分回覆函 / 前往繳費 -->
                        <a href="download/居家醫療-積分回覆函.docx" class="small" download>積分回覆函</a>
                        <a href="register_points_search_payment.php" class="payment small">前往繳費</a>
                        <!-- <a href="download/積分表格檔範本.xls" class="small" download>積分表格檔</a> -->
                        <!-- <a href="#/" class="receipt small" download>下載收據</a> -->
                        <!-- <a href="register_points_search_upload.php" class="upload small">
                          上傳成果報告</a> -->
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td data-title="活動日期" class="nowrap"><p>2020/03/01<br> 08:00</p></td>
                    <td data-title="活動主題" class="td-main txt-left"><p>帶狀泡疹疾病負擔與疫苗介紹</p></td>
                    <td data-title="申請單位" class="td-main txt-left"><p>大安婦幼醫院</p></td>
                    <td data-title="進度" class="txt-light_green nowrap"><p>通過</p></td>
                    <td data-title="下載/操作" class="td-download td-download-multiple txt-left">
                      <div class="download_box">
                        <!-- 通過狀態：可下載收據 -->
                        <a href="download/居家醫療-積分回覆函.docx" class="small" download>積分回覆函</a>
                        <!-- <a href="register_points_search_payment.php" class="payment small">
                          前往繳費</a> -->
                        <a href="download/積分表格檔範本.xls" class="small" download>積分表格檔</a>
                        <a href="#/" class="receipt small" download>下載收據</a>
                        <a href="register_points_search_upload.php" class="upload small">上傳成果報告</a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td data-title="活動日期" class="nowrap"><p>2020/03/01<br> 08:00</p></td>
                    <td data-title="活動主題" class="td-main txt-left"><p>女性生殖力衰竭_如何保有一線生機</p></td>
                    <td data-title="申請單位" class="td-main txt-left"><p>埔基醫療財團法人埔里基督教醫院</p></td>
                    <td data-title="進度" class="txt-red nowrap"><p>未通過</p></td>
                    <td data-title="下載/操作" class="td-download td-download-multiple txt-left">
                      <div class="download_box">
                        <!-- 未通過狀態：無任何操作 -->
                        <!-- <a href="download/居家醫療-積分回覆函.docx" class="small" download>積分回覆函</a> -->
                        <!-- <a href="register_points_search_payment.php" class="payment small">
                          前往繳費</a> -->
                        <!-- <a href="download/積分表格檔範本.xls" class="small" download>積分表格檔</a> -->
                        <!-- <a href="#/" class="receipt small" download>下載收據</a> -->
                        <!-- <a href="register_points_search_upload.php" class="upload small">
                          上傳成果報告</a> -->
                      </div>
                    </td>
                  </tr>
                <!-- <?php } ?> -->
              </tbody>
            </table>
          </div>

          <form class="pagination-form" name="pageche" method="post" action="">
            <div class="page" id="page_list">
              <ul>
                <!-- 跳至首頁 -->
                <a href="?sub=1" data-page="">
                  <li class="pref_start"><img src="img/index/icon_arrow_r2.svg" alt=""></li>
                </a>
                <!-- 前一頁 -->
                <a href="?sub=3" data-page="">
                  <li class="pref"><img src="img/index/icon_arrow_r.svg" alt=""></li>
                </a>
                <a href="" data-page="1">
                  <li class="active num">1</li>
                </a>
                <a href="?sub=2" data-page="2">
                  <li class="num">2</li>
                </a>
                <a href="?sub=3" data-page="3">
                  <li class="num">3</li>
                </a>
                <!-- 下一頁 -->
                <a href="?sub=2" data-page="">
                  <li class="next"><img src="img/index/icon_arrow_r.svg" alt=""></li>
                </a>
                <!-- 跳至尾頁 -->
                <a href="?sub=3" data-page="">
                  <li class="next_end"><img src="img/index/icon_arrow_r2.svg" alt=""></li>
                </a>
              </ul>
            </div>
          </form>
        </div>

        </div>
      </section>
    </main>
  </div>
</template>

<script>
import PageSubHeader from '@/components/PageSubHeader.vue';
import { RegisterPointSubHeader } from '@/lib/const';

export default {
  name: 'RegisterPointSearch',
  data() {
    return {
      ...RegisterPointSubHeader,
    };
  },
  components: {
    PageSubHeader,
  },
};
</script>
